<!-- analysisReport -->
<template>
  <div class="w center_all analysisReport" v-loading="loading">
    <div class="center_2">
      <div class="download-btn" @click="printOut(reportTitle)" :class="isDisabled ? 'download-btn-disabled' : ''">
        <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
        <img v-else :src="downloadReportImg" alt="" />
        下载报告
      </div>
      <div class="analysisReport-content" id="analysisReportContent">
        <div class="header">
          <div class="title">
            {{ reportTitle }}
          </div>
          <div class="icons">
            <div class="line"></div>
            <img class="small" :src="smallStar" alt="" />
            <img class="big" :src="bigStar" alt="" />
            <img class="small" :src="smallStar" alt="" />
            <div class="line"></div>
          </div>
          <div class="dates">
            <div>数据统计日期：{{ todayDate }}</div>
            <div>文献分析范围：{{ spreadDate }}</div>
          </div>
        </div>
        <div class="main-content-box">
          <div class="part part1">
            <h3>引证文献作者覆盖总览</h3>
            <div class="part1-content">
              <div class="part1-content-top flex">
                <div class="content1">
                  <img class="mag-img" src="@/assets/img/analysisReport/img1.png" alt="" />
                  <div class="numBox">
                    <p>
                      本刊发表文献<span>{{ totalNumDataInfo.magArticleCount }}</span
                      >篇，其中<span>{{ totalNumDataInfo.count }}</span
                      >篇共被<span>{{ totalNumDataInfo.citationsCount }}</span
                      >篇文献引用
                    </p>
                  </div>
                  <img class="arrow-down" src="@/assets/img/analysisReport/arrow-down.png" alt="" />
                </div>
                <img class="arrow-right" src="@/assets/img/analysisReport/arrow-right.png" alt="" />
                <div class="content2">
                  <div
                    id="chartYZWX"
                    :style="{
                      width: '115px',
                      height: '115px'
                    }"
                  />
                  <div class="numBox">
                    <p v-for="(item, index) in totalNumDataInfo.citationsArticleCountGroupByType" :key="index">
                      <span class="dot" :style="{ background: colorArr[index] }"></span>{{ item.type }}：<span>{{ item.count }}</span
                      >篇
                    </p>
                  </div>
                  <img class="arrow-down" src="@/assets/img/analysisReport/arrow-down.png" alt="" />
                </div>
                <img class="arrow-right" src="@/assets/img/analysisReport/arrow-right.png" alt="" />
                <div class="content3">
                  <div
                    id="chartQKWX"
                    :style="{
                      width: '115px',
                      height: '115px'
                    }"
                  />
                  <div class="numBox">
                    <p>
                      引证文献中发表在学术期刊上的共<span>{{ totalNumDataInfo.citationsMagArticleCount }}</span
                      >篇
                    </p>
                  </div>
                  <img class="arrow-down" src="@/assets/img/analysisReport/arrow-down.png" alt="" />
                </div>
              </div>
              <div class="part1-content-bottom flex">
                <div class="content1">
                  <div
                    id="chartXZFG"
                    :style="{
                      width: '115px',
                      height: '115px'
                    }"
                  />
                  <div class="numBox">
                    <p>
                      本刊发表文献中的<span>{{ totalNumDataInfo.magAuthorArticleCount }}</span
                      >篇文献作者在方正学者库中，占比<span>{{ rate1 }}</span>
                    </p>
                  </div>
                </div>
                <div class="content2">
                  <div
                    id="chartYZWX2"
                    :style="{
                      width: '115px',
                      height: '115px'
                    }"
                  />
                  <div class="numBox">
                    <p>
                      引证文献中的<span>{{ totalNumDataInfo.citationsAuthorArticleCount }}</span
                      >篇文献作者在方正学者库中，占比<span>{{ rate2 }}</span>
                    </p>
                  </div>
                </div>
                <div class="content3">
                  <div
                    id="chartXZFG2"
                    :style="{
                      width: '115px',
                      height: '115px'
                    }"
                  />
                  <div class="numBox">
                    <p>
                      期刊文献中的<span>{{ totalNumDataInfo.citationsMagAuthorArticleCount }}</span
                      >篇文献作者在方正学者库中，占比<span>{{ rate3 }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part part2">
            <h3>高被引文献（被引Top10）的引证文献作者覆盖统计</h3>
            <SimpleTable ref="firstTable" alignText="left" no-pagination serialText="排名" :table-data="firstTableData" :column-data="firstColumnData" />
            <!-- <p class="tip">学者覆盖：方正学者库中关于引证文献作者的覆盖情况。举例，若引证文献100篇有80篇文献的作者已在方正学者库，则覆盖度为80%。</p> -->
          </div>
          <div class="part part3">
            <h3>高引用/引证期刊学者覆盖统计</h3>
            <div class="flex-content">
              <div class="left-content">
                <div class="first-title"><span></span>高引用期刊（引用次数Top10）学者覆盖统计</div>
                <SimpleTable ref="secondTable" alignText="left" no-pagination serialText="排名" :table-data="secondTableData" :column-data="secondColumnData" />
              </div>
              <div class="right-content">
                <div class="first-title"><span></span>高引证期刊（引证次数Top10）学者覆盖统计</div>
                <SimpleTable ref="thirdTable" alignText="left" no-pagination serialText="排名" :table-data="thirdTableData" :column-data="thirdColumnData" />
              </div>
            </div>
          </div>
          <div class="part part4">
            <h3>高发文/引用/引证机构学者储备统计</h3>
            <div class="first-title"><span></span>本刊发文机构（发文Top10）学者储备统计</div>
            <SimpleTable ref="fourthTable" alignText="left" no-pagination serialText="排名" :table-data="fourthTableData" :column-data="fourthColumnData" />
            <div class="flex-content">
              <div class="left-content">
                <div class="first-title"><span></span>引用机构（引用次数Top10）学者储备统计</div>
                <SimpleTable ref="fifthTable" alignText="left" no-pagination serialText="排名" :table-data="fifthTableData" :column-data="fifthColumnData" />
              </div>
              <div class="right-content">
                <div class="first-title"><span></span>引证机构（引证次数Top10）学者储备统计</div>
                <SimpleTable ref="sixthTable" alignText="left" no-pagination serialText="排名" :table-data="sixthTableData" :column-data="sixthColumnData" />
              </div>
            </div>
          </div>
          <div class="part part5">
            <h3>高频关键词学者储备统计</h3>
            <div class="first-title"><span></span>引证文献高频关键词（频次Top10）的相关学者储备统计</div>
            <div class="flex-content">
              <div class="left-content">
                <div
                  id="chartKeywords"
                  :style="{
                    width: '100%',
                    height: '450px'
                  }"
                ></div>
              </div>
              <div class="right-content">
                <SimpleTable class="GJCTable" ref="thirdTable" alignText="left" no-pagination serialText="排名" :table-data="seventhTableData" :column-data="seventhColumnData" />
              </div>
            </div>
          </div>
        </div>
        <p class="tip">北京北大方正电子有限公司提供技术支持</p>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMagDetail } from '@/api/detail'
import SimpleTable from '@/components/SimpleTable'
import { getPdf } from '@/assets/js/htmlToPdf.js'
import {
  GetSumIssueArticleCitation,
  GetcountRootArticleCitationsAuthorCount,
  GetReferenceAuthorCount,
  GetCitationAuthorCount,
  GetInstitutionAuthorCount,
  GetReferenceInstitutionAuthorCount,
  GetCitationInstitutionAuthorCount,
  GetCountCitatinsArticleKeywords
} from '@/api/emailPush'
export default {
  name: 'AnalysisReport',
  data() {
    return {
      loading: true,
      isDisabled: true,
      downloadReportImg: require('@/assets/img/download-report.png'),
      downloadReportDisabledImg: require('@/assets/img/download-report-disabled.png'),
      smallStar: require('@/assets/img/star-small.png'),
      bigStar: require('@/assets/img/star-big.png'),
      magDetail: {},
      totalNumDataInfo: {},
      colorArr: ['#5470C6 ', '#91CC75', '#FAC858', '#EE6666', '#37CBCB', '#90C340', '#F6B1B4', '#FC8452'],
      firstTableData: [],
      firstColumnData: [
        {
          title: '被引文献',
          notSortable: true,
          prop: 'rootTitle'
        },
        {
          title: '刊期',
          notSortable: true,
          prop: 'yearIssue',
          width: '180px'
        },
        {
          title: '引证文献篇数',
          notSortable: true,
          prop: 'articleCount',
          align: 'center',
          width: '140px'
        },
        {
          title: '学者库学者覆盖篇数',
          notSortable: true,
          prop: 'authorArticleCount',
          align: 'center',
          width: '180px'
        },
        {
          title: '学者人数',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '140px'
        }
      ],
      secondTableData: [],
      secondColumnData: [
        {
          title: '引用期刊名称',
          notSortable: true,
          prop: 'name',
          width: '104px'
        },
        {
          title: '引用文献篇数',
          notSortable: true,
          prop: 'articleCount',
          align: 'center',
          width: '104px'
        },
        {
          title: '学者库学者覆盖篇数',
          notSortable: true,
          prop: 'authorArticleCount',
          align: 'center',
          width: '148px'
        },
        {
          title: '学者人数',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '74px'
        }
      ],
      thirdTableData: [],
      thirdColumnData: [
        {
          title: '引证期刊名称',
          notSortable: true,
          prop: 'name',
          width: '104px'
        },
        {
          title: '引证文献篇数',
          notSortable: true,
          prop: 'articleCount',
          align: 'center',
          width: '104px'
        },
        {
          title: '学者库学者覆盖篇数',
          notSortable: true,
          prop: 'authorArticleCount',
          align: 'center',
          width: '148px'
        },
        {
          title: '学者人数',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '74px'
        }
      ],
      fourthTableData: [],
      fourthColumnData: [
        {
          title: '本刊机构名称',
          notSortable: true,
          prop: 'name'
        },
        {
          title: '发文量',
          notSortable: true,
          prop: 'count',
          align: 'center',
          width: '140px'
        },
        {
          title: '学者储备量',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '140px'
        }
      ],
      fifthTableData: [],
      fifthColumnData: [
        {
          title: '引用机构名称',
          notSortable: true,
          prop: 'name'
        },
        {
          title: '引用次数',
          notSortable: true,
          prop: 'count',
          align: 'center',
          width: '100px'
        },
        {
          title: '学者储备量',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '100px'
        }
      ],
      sixthTableData: [],
      sixthColumnData: [
        {
          title: '引证机构名称',
          notSortable: true,
          prop: 'name'
        },
        {
          title: '引证次数',
          notSortable: true,
          prop: 'count',
          align: 'center',
          width: '100px'
        },
        {
          title: '学者储备量',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '100px'
        }
      ],
      seventhTableData: [],
      seventhColumnData: [
        {
          title: '引证文献关键词',
          notSortable: true,
          prop: 'keyword'
        },
        {
          title: '频次',
          notSortable: true,
          prop: 'count',
          align: 'center',
          width: '100px'
        },
        {
          title: '学者储备量',
          notSortable: true,
          prop: 'authorCount',
          align: 'center',
          width: '100px'
        }
      ]
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    magId() {
      return this.$route.query.magId
    },
    startTime() {
      return this.$route.query.startTime
    },
    endTime() {
      return this.$route.query.endTime
    },
    reportTitle() {
      return '《' + this.magDetail.platMagName + '》邮件推送目标学者覆盖测评'
    },
    todayDate() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      return year + '年' + month + '月' + day + '日'
    },
    spreadDate() {
      var startYear = this.startTime ? this.startTime.split('-')[0] : ''
      var startMonth = this.startTime ? this.startTime.split('-')[1] : ''
      var startText = (startYear ? startYear + '年' : '') + (startMonth ? '第' + ~~startMonth + '期' : '')
      var endYear = this.endTime ? this.endTime.split('-')[0] : ''
      var endMonth = this.endTime ? this.endTime.split('-')[1] : ''
      var endText = (endYear ? endYear + '年' : '') + (endMonth ? '第' + ~~endMonth + '期' : '')
      return startText + '-' + endText
    },
    rate1() {
      return ((this.totalNumDataInfo.magAuthorArticleCount / this.totalNumDataInfo.magArticleCount) * 100).toFixed(2) + '%'
    },
    rate2() {
      return ((this.totalNumDataInfo.citationsAuthorArticleCount / this.totalNumDataInfo.citationsCount) * 100).toFixed(2) + '%'
    },
    rate3() {
      return ((this.totalNumDataInfo.citationsMagAuthorArticleCount / this.totalNumDataInfo.citationsMagArticleCount) * 100).toFixed(2) + '%'
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      Promise.allSettled([
        // 期刊详情
        this.getMagDetail(),
        // 近五年引证文献作者覆盖总览
        this.getSumIssueArticleCitation(),
        // 近五年高被引文献（Top10）的引证文献作者覆盖统计
        this.getcountRootArticleCitationsAuthorCount(),
        // 高引用期刊（引用次数Top10）学者覆盖情况
        this.getReferenceAuthorCount(),
        // 高引证期刊（引证次数Top10）学者覆盖情况
        this.getCitationAuthorCount(),
        // 本刊发文机构（发文Top10）学者储备统计
        this.getInstitutionAuthorCount(),
        // 引用机构（引用次数Top10）学者储备统计
        this.getReferenceInstitutionAuthorCount(),
        // 引证机构（引证次数Top10）学者储备统计
        this.getCitationInstitutionAuthorCount(),
        // 被引文献高频关键词（频次Top10）的相关学者储备统计
        this.getCountCitatinsArticleKeywords()
      ])
        .then(() => {
          this.loading = false
          this.isDisabled = false
        })
        .catch((err) => {
          console.log('reject:', err)
        })
    },
    getMagDetail() {
      const params = {
        id: this.magId
      }
      GetMagDetail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.magDetail = data
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    getSumIssueArticleCitation() {
      let params = {
        magId: this.magId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      GetSumIssueArticleCitation(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          this.totalNumDataInfo = data
          this.chartYZWX(data)
          this.chartQKWX(data)
          this.chartXZFG(data)
          this.chartYZWX2(data)
          this.chartXZFG2(data)
        }
      })
    },
    chartYZWX(data) {
      if (!document.getElementById('chartYZWX')) {
        return
      }
      var dataArr = data.citationsArticleCountGroupByType
      if (dataArr && dataArr.length > 0) {
        dataArr.map((item) => {
          item.name = item.type
          item.value = item.count
        })
      }
      let _this = this
      const myChart = this.$echarts.init(document.getElementById('chartYZWX'))
      myChart.setOption(
        {
          // tooltip: {
          //   trigger: 'item',
          //   formatter: '{a} <br/>{b}: {c}篇 ({d}%)'
          // },
          color: _this.colorArr,
          title: {
            text: _this.totalNumDataInfo.citationsCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '引证文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '引证文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: dataArr
            }
          ]
        },
        true
      )
    },
    chartQKWX(data) {
      if (!document.getElementById('chartQKWX')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartQKWX'))
      myChart.setOption(
        {
          color: '#5470C6',
          title: {
            text: data.citationsMagArticleCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '期刊文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '期刊文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsMagArticleCount
                }
              ]
            }
          ]
        },
        true
      )
    },
    chartXZFG(data) {
      if (!document.getElementById('chartXZFG')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartXZFG'))
      myChart.setOption(
        {
          color: ['#37CBCB', '#E5E8F0'],
          title: {
            text: data.magAuthorArticleCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '学者覆盖',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '学者覆盖',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.magArticleCount
                },
                { value: data.magArticleCount - data.magAuthorArticleCount }
              ]
            }
          ]
        },
        true
      )
    },
    chartYZWX2(data) {
      if (!document.getElementById('chartYZWX2')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartYZWX2'))
      myChart.setOption(
        {
          color: ['#37CBCB', '#E5E8F0'],
          title: {
            text: data.citationsAuthorArticleCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '学者覆盖',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '学者覆盖',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsAuthorArticleCount
                },
                { value: data.citationsCount - data.citationsAuthorArticleCount }
              ]
            }
          ]
        },
        true
      )
    },
    chartXZFG2(data) {
      if (!document.getElementById('chartXZFG2')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartXZFG2'))
      myChart.setOption(
        {
          color: ['#37CBCB', '#E5E8F0'],
          title: {
            text: data.citationsMagAuthorArticleCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '学者覆盖',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '学者覆盖',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: data.citationsMagAuthorArticleCount
                },
                { value: data.citationsMagArticleCount - data.citationsMagAuthorArticleCount }
              ]
            }
          ]
        },
        true
      )
    },
    // 近五年高被引文献（Top10）的引证文献作者覆盖统计
    getcountRootArticleCitationsAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        startTime: this.startTime,
        endTime: this.endTime,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetcountRootArticleCitationsAuthorCount(params)
          .then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              const { content = [] } = data
              this.firstTableData = content
              if (this.firstTableData.length > 0) {
                this.firstTableData.map((item) => {
                  item.yearIssue = (item.year ? item.year + '年' : '') + (item.magNumber ? '第' + ~~item.magNumber + '期' : '')
                })
              }
              resolve()
            } else {
              const { message } = res.data
              this.$message.error(message)
              reject()
            }
          })
          .catch((err) => {
            console.log('reject:', err)
          })
      })
    },
    // 高引用期刊（引用次数Top10）学者覆盖情况
    getReferenceAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetReferenceAuthorCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.secondTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 高引证期刊（引证次数Top10）学者覆盖情况
    getCitationAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetCitationAuthorCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.thirdTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 本刊发文机构（发文Top10）学者储备统计
    getInstitutionAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetInstitutionAuthorCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.fourthTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引用机构（引用次数Top10）学者储备统计
    getReferenceInstitutionAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetReferenceInstitutionAuthorCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.fifthTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引证机构（引证次数Top10）学者储备统计
    getCitationInstitutionAuthorCount(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetCitationInstitutionAuthorCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.sixthTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    //被引文献高频关键词（频次Top10）的相关学者储备统计
    getCountCitatinsArticleKeywords(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        startTime: this.startTime,
        endTime: this.endTime,
        page: page - 1,
        size
      }
      return new Promise((resolve, reject) => {
        GetCountCitatinsArticleKeywords(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.seventhTableData = content
            this.chartKeywords(content)
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    chartKeywords(data) {
      if (!document.getElementById('chartKeywords')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartKeywords'))
      let list = []
      if (data && data.length > 0) {
        list = data
        list.map((item) => {
          item.name = item.keyword
          item.value = item.count
        })
        console.log(list, 'list')
        myChart.setOption(
          {
            //数据可以点击
            tooltip: {},
            series: [
              {
                //词的类型
                type: 'wordCloud',
                //设置字符大小范围
                sizeRange: [16, 70],
                size: ['100%', '100%'],
                rotationRange: [-90, 90],
                width: '95%',
                height: '95%',
                gridSize: 10,
                textStyle: {
                  normal: {
                    //生成随机的字体颜色
                    color: () => {
                      return 'rgb(' + [Math.round(Math.random() * 160), Math.round(Math.random() * 160), Math.round(Math.random() * 160)].join(',') + ')'
                    }
                  }
                },
                //不要忘记调用数据
                data: list
              }
            ]
          },
          true
        )
      }
    },
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.$nextTick(() => {
        let pdfWidth = document.getElementsByClassName('analysisReport-content')[0].offsetWidth / 2
        let pdfHeight = document.getElementsByClassName('analysisReport-content')[0].offsetHeight / 2
        getPdf('analysisReportContent', name, pdfWidth, pdfHeight, true)
      })
    }
  }
}
</script>
<style lang="scss">
.analysisReport {
  .el-table .cell,
  .el-table--border .el-table__cell:first-child .cell {
    padding: 0 9px !important;
  }
  .el-table {
    overflow-y: auto !important;
  }
  .GJCTable {
    .serial1,
    .serial2,
    .serial3 {
      width: 20px;
      height: 20px;
      background: #a6aabb;
      border-radius: 10px;
      display: inline-block;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
  }
  .el-loading-spinner {
    top: 5% !important;
  }
}
</style>
<style lang="scss" scoped>
.analysisReport {
  .download-btn {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    padding: 30px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .first-title {
    color: #333333;
    font-size: 18px;
    line-height: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 4px;
      height: 18px;
      background: #4054af;
      margin: 0 6px 0 0;
    }
  }
  .analysisReport-content {
    margin: auto;
    background-color: #f5f7fd;
    border: 1px solid #f5f7fd;
    padding: 40px;
    .header {
      padding-top: 80px;
      padding-bottom: 20px;
      .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4054af;
        line-height: 36px;
        text-align: center;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 33px;
        .line {
          width: 46%;
          height: 2px;
          border-top: 1px solid #666666;
          border-bottom: 1px solid #666666;
        }
        .small {
          margin: 0 15px;
        }
      }
      .dates {
        text-align: right;
        margin-top: 55px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin-right: 10px;
      }
    }
    .main-content-box {
      background-color: #fff;
      padding: 40px;
      h3 {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: left;
      }
      .flex-content {
        display: flex;
        justify-content: space-between;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .part1 {
        .part1-content {
          margin-top: 30px;
          background: #f5f7fd;
          padding: 30px;
          .flex {
            display: flex;
            align-items: center;
          }
          .mag-img {
            width: 115px;
            height: 76px;
          }
          .arrow-right {
            width: 54px;
            height: 19px;
            margin: 0 20px;
          }
          .arrow-down {
            width: 19px;
            height: 28px;
          }
          .part1-content-top,
          .part1-content-bottom {
            .content1,
            .content2,
            .content3 {
              display: flex;
              width: 30%;
              align-items: center;
              text-align: left;
              position: relative;
              .numBox {
                text-align: left;
                margin-left: 10px;
                color: #666;
                font-size: 14px;
                flex: 1;
                .dot {
                  width: 8px;
                  height: 8px;
                  display: inline-block;
                  border-radius: 50%;
                  margin-right: 4px;
                }
                span {
                  color: #4054af;
                }
              }
            }
            .content1 {
              .arrow-down {
                position: absolute;
                bottom: -54px;
                left: 50px;
              }
            }
            .content2,
            .content3 {
              .arrow-down {
                position: absolute;
                bottom: -40px;
                left: 50px;
              }
            }
          }
          .part1-content-bottom {
            margin-top: 50px;
            justify-content: space-between;
            .content1,
            .content2,
            .content3 {
              width: 26%;
            }
          }
        }
      }
      .part2 {
        margin-top: 50px;
        h3 {
          margin-bottom: 27px;
        }
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ccc;
          text-align: center;
          margin-top: 18px;
        }
      }
      .part3 {
        margin-top: 50px;
        h3 {
          margin-bottom: 30px;
        }
      }
      .part4 {
        margin-top: 50px;
        h3 {
          margin-bottom: 30px;
        }
        .flex-content {
          margin-top: 30px;
        }
      }
      .part5 {
        margin-top: 50px;
        h3 {
          margin-bottom: 20px;
        }
        .flex-content {
          background: #f5f7fd;
          padding: 20px;
        }
      }
    }
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a6aabb;
      line-height: 20px;
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>
